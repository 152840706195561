<template src="./index.html">
</template>

<script>
import {
  mapState,
  mapActions
} from 'vuex'
import SolutionMainlistV2 from '_c/mainlistv2/Solution'

export default {
  async asyncData ({ store }) {
    const DISPATCH = store.dispatch
    let sid = +store.state.route.params.id || 0

    return await Promise.all([
      DISPATCH('commonData/setCurrentPage', 'solutiondetail'),
      DISPATCH('dataSolution/getSolutionDetail', {
        id: sid
      })
    ])
  },
  computed: {
    ...mapState('dataSolution', {
      detail: s => s.detail
    }),
    listData() {
      if (!this.detail?.id) {
        return {
          total: 0,
          list: []
        }
      }
      return {
        total: 1,
        list: [this.detail]
      }
    }
  },
  components:{
    SolutionMainlistV2
  }
}
</script>
